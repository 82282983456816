<script setup lang="ts">
import { IconButton, Icon } from 'webcc-ui-components'
import type { MenuItem } from '~/composables/navigation'
import { ANNUAL_REPORT_ANCHOR } from '~/components/payment/constants'

const { t } = useI18n()
const { setModalOpen } = useNavigation()
const { $ROUTE, $unleash, $auth } = useNuxtApp()
const { open: openErv } = useErv()

const eventBus = useEventBus<boolean>('guest-popup')

const hasYellowDot = useHasContractPriceYellowDot()
const selectedItem = ref()

const { data: accommodations } = useAccommodationsQuery()

const contractAvailableAccommodationCode = computed(() => {
  if (
    $unleash.features['document-downloads'] &&
    $auth.profile.permissions?.ViewDocumentDownloads
  ) {
    const availableAccommodations =
      accommodations.value?.filter(
        (accommodation) => accommodation.contractPdfAvailable,
      ) ?? []

    return availableAccommodations[0]?.accommodationCode
  }
  return undefined
})

const showGuestsData = computed(() => {
  return !!(
    $auth.profile.present && $auth.profile.permissions?.DownloadTravellerData
  )
})
const allMenuItems = computed(() => {
  return [
    /** Main Page */
    {
      routeName: 'index',
      subcategory: t('components.navigation.main'),
      title: t('components.common.home'),
      url: $ROUTE.INDEX,
    },
    {
      subcategory: t('components.navigation.main'),
      hide: !$auth.profile.permissions?.ViewPayments,
      title: t('components.navigation.payments'),
      children: [
        {
          routeName: 'payments-overview',
          title: t('components.common.paymentsOverview'),
          url: $ROUTE.PAYMENTS_OVERVIEW,
        },
        {
          routeName: 'payments-overview-statements',
          title: t('components.common.statements'),
          url: $ROUTE.PAYMENTS_STATEMENTS,
        },
        {
          title: t('components.navigation.taxReturn'),
          url: `${$ROUTE.PAYMENTS_OVERVIEW}#${ANNUAL_REPORT_ANCHOR}`,
        },
      ],
    },
    {
      subcategory: t('components.navigation.main'),
      title: t('components.common.bookings'),
      children: [
        {
          routeName: 'reservations-type',
          title: t('components.bookings.arrivalsAndDepartures'),
          url: $ROUTE.RESERVATIONS_ARRIVALS,
        },
        {
          routeName: 'bookings-calendar',
          title: t('components.navigation.calendar'),
          url: $ROUTE.BOOKINGS_CALENDAR,
          hide: !$auth.profile.permissions?.ViewBookingCalendar,
        },
        {
          title: t('components.navigation.guestsData'),
          hide: !showGuestsData.value,
          event: 'open-guests-data',
        },
      ],
    },

    {
      subcategory: t('components.navigation.main'),
      title: t('components.bookings.properties'),
      children: [
        {
          routeName: 'contract-prices-slug',
          title: t('components.contractPrices.title'),
          url: $ROUTE.CONTRACT_PRICES,
          hide:
            !$unleash.features['contract-prices'] ||
            !$auth.profile.permissions?.ViewContractPrices,
        },
        {
          routeName: 'property-benchmark',
          title: t('components.common.propertyBenchmark'),
          url: $ROUTE.PROPERTY_BENCHMARK,
          hide:
            !$unleash.features['property-benchmark'] ||
            !$auth.profile.permissions?.ViewPropertyBenchmark,
        },
        {
          routeName: 'reviews',
          title: t('components.common.guestsReviews'),
          url: $ROUTE.REVIEWS,
        },
      ],
    },
    {
      routeName: 'opportunities',
      subcategory: t('components.navigation.main'),
      hide:
        !$unleash.features.opportunities ||
        !$auth.profile.permissions?.ViewOpportunities,
      title: t('components.navigation.opportunities'),
      url: $ROUTE.OPPORTUNITIES,
    },
    {
      routeName: 'inbox',
      subcategory: t('components.navigation.main'),
      title: t('components.common.inbox'),
      url: $ROUTE.INBOX,
    },
    {
      subcategory: t('components.common.supportCenter'),
      title: t('components.common.supportCenter'),
      children: [
        {
          routeName: 'contact-list',
          title: t('components.common.contactList'),
          url: $ROUTE.CONTACT_LIST,
        },
        {
          title: t('components.navigation.contactInsurance'),
          action: openErv,
        },
        {
          routeName: 'contact-us',
          title: t('components.common.contactUs'),
          url: $ROUTE.CONTACT_US,
        },
      ],
    },
    {
      subcategory: t('components.navigation.profile'),
      title: t('components.navigation.profile'),
      children: [
        {
          routeName: 'profile',
          subcategory: t('components.navigation.profile'),
          title: t('components.profile.Email'),
          url: $ROUTE.PROFILE,
        },
        {
          routeName: 'profile-change-address',
          subcategory: t('components.navigation.profile'),
          title: t('components.profile.Address'),
          url: $ROUTE.PROFILE_CHANGE_ADDRESS,
        },
        {
          routeName: 'profile-change-phone',
          subcategory: t('components.navigation.profile'),
          title: t('components.profile.Phone'),
          url: $ROUTE.PROFILE_CHANGE_PHONE,
        },
        {
          routeName: 'profile-change-password',
          subcategory: t('components.navigation.profile'),
          title: t('components.login.password'),
          url: $ROUTE.PROFILE_CHANGE_PASSWORD,
        },
        {
          routeName: 'profile-payment-info',
          subcategory: t('components.navigation.profile'),
          hide:
            !$auth.profile.permissions?.ViewPayments ||
            !(
              $unleash.features['payments-change-iban'] ||
              $unleash.features['payments-pay-periodicity']
            ),
          title: t('components.common.paymentInfo'),
          url: $ROUTE.PROFILE_PAYMENT_INFO,
        },
        {
          routeName: 'profile-notifications',
          subcategory: t('components.navigation.profile'),
          title: t('components.profile.notifications'),
          url: $ROUTE.PROFILE_NOTIFICATIONS,
        },
      ],
    },
    {
      subcategory: t('components.navigation.profile'),
      title: t('components.document.downloads'),
      url: `${$ROUTE.DOCUMENT_DOWNLOADS}/${contractAvailableAccommodationCode.value}`,
      hide: !contractAvailableAccommodationCode.value,
    },
    {
      subcategory: t('components.navigation.profile'),
      title: t('components.common.logout'),
      action: $auth.logout,
    },
  ].filter(Boolean) as MenuItem[]
})
const visibleMenuItems = computed(() => {
  /** Showing selectedItem`s childrens or allMenuItems */
  const currentNodeChildren = selectedItem.value
    ? selectedItem.value.children
    : allMenuItems.value

  /** Adding option `subcategory: '';` if there is no such a field */
  return currentNodeChildren
    .filter(({ hide }: MenuItem) => !hide)
    .map((el: MenuItem) => {
      const subcategory = el.subcategory || ''

      return {
        ...el,
        subcategory,
      }
    })
})
const visibleSubcategoriesNames = computed(() => {
  const subcategories: string[] = visibleMenuItems.value.map(
    (item: MenuItem) => item.subcategory,
  )
  return subcategories.filter(
    (subcategory, index, self) => self.indexOf(subcategory) === index,
  )
})

function menuItemsFilteredBySubcategory(subcategoryName: string) {
  return visibleMenuItems.value.filter(
    (el: MenuItem) => el.subcategory === subcategoryName,
  )
}

function clearSelectedItem() {
  selectedItem.value = undefined
}

function handleClick(menuItem: MenuItem) {
  if (menuItem.event && 'event' in menuItem) {
    eventBus.emit(true)
  } else if (typeof menuItem.action === 'function') {
    menuItem.action()
    setModalOpen(false)
  } else if (menuItem.url) {
    if (menuItem.routeName === useRoute().name) {
      setModalOpen(false)
    } else {
      navigateTo(menuItem.url)
      setModalOpen(false)
    }
  }

  if (menuItem.children) {
    selectedItem.value = menuItem
  }
}

function isSupportCenterSubcategory(categoryName: string) {
  return t('components.common.supportCenter') === categoryName
}
</script>

<template>
  <div data-id="navigation-modal" class="grow overflow-scroll bg-white pb-4">
    <div
      v-if="selectedItem"
      class="mb-6 flex items-center border-b border-bgr-200 px-4 py-4"
    >
      <IconButton
        icon="arrow-left"
        variant="transparent"
        size="md"
        @click="clearSelectedItem"
      />
      <span class="text-base font-medium text-txt">
        {{ selectedItem.title }}
      </span>
    </div>

    <div
      v-else
      data-id="navigation-modal-head"
      class="border-y border-bgr-300 bg-bgr-100"
    >
      <div class="grid gap-y-2 px-4 pt-6 pb-8">
        <p class="text-xs font-medium uppercase leading-3.5 text-txt-500">
          {{ $t('components.navigation.connected') }}
        </p>

        <div class="flex items-center justify-between">
          <span class="text-sm font-medium leading-3.5 text-txt-400">
            {{ $t('components.navigation.ownerId') }}
          </span>
          <span class="text-base font-normal leading-4.5">
            {{ $auth.profile.userID }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-for="categoryName in visibleSubcategoriesNames"
      :key="categoryName"
      data-id="navigation-modal-list"
    >
      <p
        v-if="categoryName && !isSupportCenterSubcategory(categoryName)"
        class="mx-4 mt-6 text-xs uppercase text-txt-500"
      >
        {{ categoryName }}
      </p>

      <NavigationModalCards
        :class="
          isSupportCenterSubcategory(categoryName)
            ? 'mt-6 border-y border-bgr-300 pt-6 pb-8'
            : 'mt-2 grid gap-y-2'
        "
        :items="menuItemsFilteredBySubcategory(categoryName)"
        @click="handleClick"
      >
        <template #title-prepend="{ title }">
          <div
            v-if="
              hasYellowDot &&
              (title === $t('components.bookings.properties') ||
                title === $t('components.contractPrices.title'))
            "
          >
            <BasicDot class="inline-block" size="xm" />
          </div>
        </template>

        <template v-if="isSupportCenterSubcategory(categoryName)">
          <div class="flex items-center space-x-2">
            <Icon graphic="chat-question" size="md" class="text-txt-500" />
            <span class="text-sm font-medium">
              {{ $t('components.common.supportCenter') }}
            </span>
          </div>
        </template>
      </NavigationModalCards>
    </div>
  </div>
</template>
