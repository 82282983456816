<script setup lang="ts">
const MOBILE_NAVIGATION_HEIGHT = 66

const route = useRoute()
const { isModalOpen } = useNavigation()
const { registerObstacle } = useObstacle()
const { bottom, update } = useScreenSafeArea()

let unregister: ReturnType<typeof registerObstacle> | null

onMounted(() => {
  update()
  unregister = registerObstacle(
    route.path,
    MOBILE_NAVIGATION_HEIGHT,
    isStandaloneApplication() ? Number(bottom.value.replace(/px$/, '')) : 0,
  )
})

onBeforeUnmount(() => unregister?.())
</script>

<template>
  <nav data-id="navigation-wrapper" class="nav-mobile">
    <NavigationModal v-if="isModalOpen" />

    <NavigationMenu />
  </nav>
</template>

<style scoped>
.nav-mobile {
  pointer-events: none;
}

.nav-mobile > * {
  pointer-events: auto;
}
</style>
