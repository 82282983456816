<script setup lang="ts">
import { Icon } from 'webcc-ui-components'
import type { NavigationMenuItem } from '~/composables/navigation'

const { t } = useI18n()
const { $ROUTE, $auth } = useNuxtApp()
const { navigate, isModalOpen, routePath } = useNavigation()

const hasYellowDot = useHasContractPriceYellowDot()

useDynamicBodyClass(isModalOpen, 'navigation-menu-opened')

const menuItems = computed<NavigationMenuItem[]>(() => [
  {
    icon: 'home',
    label: t('components.common.home'),
    url: $ROUTE.INDEX,
    hide: false,
    isToggleMenuButton: false,
  },
  {
    icon: 'map-pin-user',
    label: t('components.dashboard.arrivals'),
    url: $ROUTE.RESERVATIONS_ARRIVALS,
    hide: false,
    isToggleMenuButton: false,
  },
  {
    icon: 'money-euro-circle',
    label: t('components.navigation.payments'),
    url: $ROUTE.PAYMENTS_OVERVIEW,
    hide: !$auth.profile.permissions?.ViewPayments,
    isToggleMenuButton: false,
  },
  {
    icon: 'menu',
    label: t('components.navigation.menu'),
    url: null,
    hide: false,
    isToggleMenuButton: true,
  },
])

const preparedMenuItems = computed(() => {
  return menuItems.value
    .filter(({ hide }) => !hide)
    .map((item: NavigationMenuItem) => {
      let isActive = false

      /** Modal toggle button */
      if (item.isToggleMenuButton) {
        isActive = isModalOpen.value
        item.icon = isActive ? 'close' : 'menu'
      } else if (item.url) {
        const isModalClosed = !isModalOpen.value

        if (item.url === $ROUTE.RESERVATIONS_ARRIVALS) {
          /** Arrivals or Departures page is active */
          isActive =
            [
              $ROUTE.RESERVATIONS_ARRIVALS,
              $ROUTE.RESERVATIONS_DEPARTURES,
            ].includes(routePath.value) && isModalClosed
          item.icon = isActive ? 'map-pin-user-filled' : 'map-pin-user'
        } else if (item.url === $ROUTE.PAYMENTS_OVERVIEW) {
          isActive =
            $ROUTE.PAYMENTS_OVERVIEW === routePath.value && isModalClosed
          item.icon = isActive
            ? 'money-euro-circle-filled'
            : 'money-euro-circle'
        } else {
          /** Link */
          isActive = item.url === routePath.value && isModalClosed
          item.icon = isActive ? 'home-filled' : 'home'
        }
      }

      return {
        ...item,
        isActive,
      }
    })
})
</script>

<template>
  <div
    data-id="navigation-menu"
    class="font-roboto w-full flex-shrink-0 bg-white opacity-100 shadow-top"
  >
    <ul class="pwa-safe-area flex h-full w-full items-stretch">
      <li
        v-for="(item, index) in preparedMenuItems"
        :key="index"
        :data-id="`navigation-menu-item-${item.label.toLowerCase()}`"
        class="flex w-full cursor-pointer items-center flex-col justify-center py-2.5 gap-y-0.5 px-3 focus:ring-0"
        :class="[
          item.icon === 'home' ? 'home-button' : null,
          item.label === $t('components.navigation.menu')
            ? 'bookings-button'
            : null,
        ]"
        tabindex="0"
        role="button"
        @click="navigate(item)"
      >
        <div class="flex relative justify-center w-min">
          <Icon
            size="lg"
            :graphic="item.icon"
            :class="[item.isActive ? 'text-thm-active' : 'text-txt-400']"
          />
          <ClientOnly>
            <BasicDot
              v-if="
                hasYellowDot &&
                item.label === t('components.navigation.menu') &&
                item.icon === 'menu'
              "
              size="md"
              class="absolute -top-1 -right-1"
            />
          </ClientOnly>
        </div>
        <label
          class="truncate text-center text-xs uppercase font-semibold leading-3"
          :class="item.isActive ? 'text-thm-active' : 'text-txt-400'"
        >
          {{ item.label }}
        </label>
      </li>
    </ul>
  </div>
</template>

<style>
.navigation-menu-opened {
  @apply overflow-hidden;
}
</style>
